
import { computed, defineComponent, onMounted, ref } from "vue";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import AddCustomerModal from "@/components/modals/forms/AddCustomerModal.vue";
import { MenuComponent } from "@/assets/ts/components";
import { Actions } from "@/store/enums/StoreEnums";
import { Statuses } from "@/store/enums/StatusEnums";
import { useStore } from "vuex";
import { replaceMonth } from "@/store/enums/months";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { statusState } from "@/core/helpers/converters";
import PaymentMethods from "@/components/subscriptions/cards/overview/PaymentMethods.vue";
import moment from "moment";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useI18n } from "vue-i18n";
import { hideModal } from "@/core/helpers/dom";

export default defineComponent({
  name: "customers-listing",
  components: {
    Datatable,
    PaymentMethods,
    AddCustomerModal,
  },
  setup() {
    const store = useStore();
    const activeCustomerId = ref("");
    const activeCustomerName = ref("");
    const { t } = useI18n();
    const getCurses = ref([]);
    const unsubscribeModalRef = ref<null | HTMLElement>(null);

    setCurrentPageBreadcrumbs(t("subscriptions"), [t("account")]);

    const tableHeader = ref([
      {
        name: t("subscriptionsName"),
        key: "subscriptionsName",
        sortable: true,
      },
      {
        name: t("group"),
        key: "group",
        sortable: true,
      },
      {
        name: t("cost"),
        key: "cost",
        sortable: true,
      },
      {
        name: t("expired"),
        key: "expired",
        sortable: true,
      },
      {
        name: t("status"),
        key: "status",
        sortable: true,
      },
      {
        name: t("actions"),
        key: "actions",
      },
    ]);

    onMounted(() => {
      MenuComponent.reinitialization();

      const courses = store.getters.getAllCurses;
      const arr = courses.map((item) => {
        return {
          _id: item._id,
          course_name: item.course_name ? item.course_name : "-",
          course_description: item.course_description
            ? item.course_description
            : "-",
          course_icon: item.course_icon ? item.course_icon : "-",
          __v: item.__v ? item.__v : 0,
          payment_cost: item.payment_cost ? item.payment_cost : "-",
        };
      });
      getCurses.value = arr;
    });

    const setData = (id, name) => {
      activeCustomerId.value = id;
      activeCustomerName.value = name;
    };

    const Unsubscribe = () => {
      console.log(activeCustomerId.value);
      store
        .dispatch(Actions.SUBSCRIPTION_UNSUBSCRIBE, { subscription_id: activeCustomerId.value })
          .then(() => {
            Swal.fire({
              text: t("unsubscribeForSuccess"),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: t("good"),
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            hideModal(unsubscribeModalRef.value);
          })
          .catch(() => {
            Swal.fire({
              text: 'Error',
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: t("tryAgain"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
            hideModal(unsubscribeModalRef.value);
          });
    };

    const Update = () => {
      console.log(activeCustomerId.value);
    };

    /**
     * Find course name by course ID
     */
    const getCourseName = (courseId) => {
      let coursesArr = getCurses.value,
          courseName,
          courseObj;
      if (coursesArr.length) {
        courseObj = coursesArr.find(item => courseId === item['_id']);
        courseName = courseObj?.course_name ?? "";
      }
      return courseName;
    };

    const getSubscriptions = computed(() => {
      const now = moment(new Date());
      const data = store.getters.subscriberInfo.subscriptions;
      if (!data) {
        return [];
      }
      const arr = data.map((item) => {
        let duration = moment.duration(moment(item.date_expired_debtor).diff(now));
        return {
          expired: item?.date_expired ? moment(item.date_expired).format("MMM DD, YYYY") : "",
          _id: item._id ? item._id : "-",
          date_expired_debtor: item.date_expired_debtor ? duration.asDays().toFixed(0) : "-",
          name: getCourseName(item.course_id),
          cost: item.payment_cost ? item.payment_cost : "-",
          status: item?.status ? item.status : "",
          state: statusState(item.status),
          group: item?.group_name ? item?.group_name : "",
        };
      });
      return arr;
    });

    return {
      tableHeader,
      Statuses,
      activeCustomerId,
      Unsubscribe,
      setData,
      activeCustomerName,
      Update,
      getSubscriptions,
      unsubscribeModalRef,
      t,
    };
  },
});
